import { faArrowLeft, faEllipsisVertical, faCopy } from '@fortawesome/free-solid-svg-icons';
import { Room, RoomEvent, setLogLevel, VideoPresets } from 'livekit-client';
import { DisplayContext, DisplayOptions, LiveKitRoom, ControlButton, MenuItem } from '@livekit/react-components';
import { useState, useEffect, ReactElement, useCallback } from 'react';
import 'react-aspect-ratio/aspect-ratio.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Popover } from 'react-tiny-popover'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Flex,
  Box,
  Spacer,
  useToast,
} from '@chakra-ui/react'

const defaultOptions = {
  method: "POST",
  headers: { "Content-Type":"application/json", },
}

export const RoomPage = () => {
  const toastId = 'code-copy'
  const toast = useToast({
    id: toastId,
    position: 'top',
    title: 'Call code copied successfully!',
    containerStyle: {
      'width': '100%',
      maxWidth: '100%',
    },
  });

  // get conde from path params
  const sessionCode: any = useParams().code;

  // get session values from locaStorage
  const existingSessionCode = localStorage.getItem('ro-code');
  const sessionToken = localStorage.getItem('ro-token');
  const sessionUrl = localStorage.getItem('ro-url');

  // set default session values
  const [existingCode, setExistingCode] = useState<any>(existingSessionCode);
  const [code] = useState<any>(sessionCode);
  const [token, setToken] = useState<any>(sessionToken);
  const [url, setUrl] = useState<any>(sessionUrl);
  const [numParticipants, setNumParticipants] = useState(0);
  const [displayOptions] = useState<DisplayOptions>({
    stageLayout: 'grid',
    showStats: false,
  });
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [isReady, setIsReady] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const recorder = query.get('recorder');

  const joinRoom = useCallback(async (code: string) => {
    let options = {
      ...defaultOptions,
      body: JSON.stringify({ code: code.toUpperCase() })      
    }

    let resp;
    try {
      resp = await fetch(`${process.env.REACT_APP_BASE_URL}/v1/calls/join`, options);
    } catch (error) {
      console.log('error', error)
      return;
    }

    const json = await resp.json();
    const activeSession = { code, token: json.token, url: json.url };
    saveSession(activeSession);
  }, []);

  useEffect(() => {
    if (numParticipants === 1) {
      onOpen()
    } else {
      onClose()
    }
  }, [numParticipants, onOpen, onClose]);

  useEffect(() => {
    const fetchData = async () => {
      await joinRoom(code)
    }

    if (!(code === existingCode && token && url)) {
      fetchData();
      return;
    }
  }, [existingCode, code, token, url, joinRoom]);

  useEffect(() => {
    if (code && token && url) {
      setIsReady(true);
    }
  }, [code, token, url]);

  const saveSession = ({ code, token, url }: { code: string, token: string, url: string }) => {
    // set locaStorage
    localStorage.setItem('ro-code', code);
    localStorage.setItem('ro-token', token);
    localStorage.setItem('ro-url', url);

    // set session values
    setExistingCode(code)
    setToken(token)
    setUrl(url)
  }

  const clearLocalStorage = () => {
    // delete localStorage
    localStorage.removeItem('ro-code');
    localStorage.removeItem('ro-token');
    localStorage.removeItem('ro-url');
  };

  const onLeave = () => {
    clearLocalStorage();
    navigate('/');
  };

  const updateParticipantSize = (room: Room) => {
    setNumParticipants(room.participants.size + 1);
  };

  const onParticipantDisconnected = (room: Room) => {
    updateParticipantSize(room);

    /* Special rule for recorder */
    if (recorder && parseInt(recorder, 10) === 1 && room.participants.size === 0) {
      console.log('END_RECORDING');
    }
  };

  const handleMenuClick = (item: MenuItem) => {
    setMenuVisible(false);
    if (item) {
      onOpen()
    }
  };

  function copyCodeToClipboard () {
    if (!code) return
    navigator.clipboard.writeText(code)
    if(toast.isActive(toastId)) return
    toast()
  }

  let menu: ReactElement = <div />;
  let menuItems: any = [{
    label: 'Meeting details'
  }];
  if (menuItems && menuItems.length > 0) {
    menu = (
      <div className='popoverMenu'>
        <ul className='list'>
          {menuItems?.map((item: any, i: any) => {
            return (
              <li key={i} onClick={() => handleMenuClick(item)}>
                {item.label}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return (
    <DisplayContext.Provider value={displayOptions}>
      <div className="roomContainer">
        <div className="topBar">
          <ControlButton
            label=""
            icon={faArrowLeft}
            onClick={onLeave}
          />
          {
            isReady && 
            <Popover
              isOpen={menuVisible}
              reposition={false}
              positions={['bottom', 'right']}
              content={menu}
            >
              <ControlButton
                label={''}
                icon={faEllipsisVertical}
                className={'menuButton'}
                onClick={() => setMenuVisible(!menuVisible)}
              />
            </Popover>
          }
        </div>
        <LiveKitRoom
          url={url}
          token={token}
          isReady={isReady}
          onConnected={(room) => {
            setLogLevel('debug');
            onConnected(room, query);
            room.on(RoomEvent.ParticipantConnected, () => updateParticipantSize(room));
            room.on(RoomEvent.ParticipantDisconnected, () => onParticipantDisconnected(room));
            updateParticipantSize(room);
          }}
          roomOptions={{
            adaptiveStream: isSet(query, 'adaptiveStream'),
            dynacast: isSet(query, 'dynacast'),
            publishDefaults: {
              simulcast: isSet(query, 'simulcast'),
            },
            videoCaptureDefaults: {
              resolution: VideoPresets.h720.resolution,
            },
          }}
          onLeave={onLeave}
        />
        {code && (
          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>You're all set-up!</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text fontSize={14}>Share the meeting code below with others who you want to join the call</Text>
                <br />
                <Flex alignItems='center'>
                  <Box>
                    <Text fontSize={16}>Code: <Text as='b'>{code.toUpperCase()}</Text></Text>
                  </Box>
                  <Spacer />
                  <Box>
                    <ControlButton
                      label={''}
                      icon={faCopy}
                      onClick={copyCodeToClipboard}
                    />
                  </Box>
                </Flex>
                <br />
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </div>
    </DisplayContext.Provider>
  );
};

async function onConnected(room: Room, query: URLSearchParams) {
  // make it easier to debug
  (window as any).currentRoom = room;

  if (isSet(query, 'audioEnabled')) {
    const audioDeviceId = query.get('audioDeviceId');
    if (audioDeviceId && room.options.audioCaptureDefaults) {
      room.options.audioCaptureDefaults.deviceId = audioDeviceId;
    }
    await room.localParticipant.setMicrophoneEnabled(true);
  }

  if (isSet(query, 'videoEnabled')) {
    const videoDeviceId = query.get('videoDeviceId');
    if (videoDeviceId && room.options.videoCaptureDefaults) {
      room.options.videoCaptureDefaults.deviceId = videoDeviceId;
    }
    await room.localParticipant.setCameraEnabled(true);
  }
}

function isSet(query: URLSearchParams, key: string): boolean {
  return query.get(key) === '1' || query.get(key) === 'true';
}
