import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { ControlButton } from '@livekit/react-components';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const AuthComplete = () => {
  const query = new URLSearchParams(useLocation().search);
  const verificationId = query.get('verification_id');
  const mobile = query.get('mobile');
  console.log(mobile, verificationId)
  
  // state to pass onto room
  const [otp, setOtp] = useState<string>('');
  const [connectDisabled, setConnectDisabled] = useState(true);
  // navigation
  const navigate = useNavigate();

  useEffect(() => {
    if (otp) {
      setConnectDisabled(false);
    } else {
      setConnectDisabled(true);
    }
  }, [otp]);

  const connectToRoom = async () => {
    let options = {
      method: "POST",
      headers: { "Content-Type":"application/json", },
      body: JSON.stringify({ verification_id: verificationId, otp: otp })      
    }

    let resp;
    try {
      resp = await fetch('https://api.chatd.reachout.dev/v1/auth/claim/complete', options);
    } catch (error) {
      console.log(error)
      return;
    }

    const json = await resp.json();
    // set locaStorage
    localStorage.setItem('jwt', json['jwt'])

    // navigate to prejoin room
    navigate({
      pathname: '/',
      search: `?jwt=${json['jwt']}`,
    });
  };

  return (
    <div className="prejoin">
      <main>
        <h2>LiveKit Video</h2>
        <hr />
        <div className="entrySection">
          <div>
            <div className="label">LiveKit URL</div>
            <div>
              <input type="text" name="otp" value={otp} onChange={(e) => setOtp(e.target.value)} />
            </div>
          </div>
          <div className="options">
            <ControlButton
              label="Connect"
              disabled={connectDisabled}
              icon={faBolt}
              onClick={connectToRoom}
            />
          </div>
        </div>
      </main>
      <footer>
        This page is built with <a href="https://github.com/livekit/livekit-react">LiveKit React</a>
        &nbsp; (
        <a href="https://github.com/livekit/livekit-react/blob/master/example/src/PreJoinPage.tsx">
          source
        </a>
        )
      </footer>
    </div>
  );
};
