import { Participant, Track, VideoTrack } from 'livekit-client';
import React, { ReactElement, useEffect, useState } from 'react';
import { ControlsView } from '../ControlsView';
import { ParticipantView } from '../ParticipantView';
import { ScreenShareView } from '../ScreenShareView';
import { StageProps } from '../StageProps';
import { defaultSortParticipants } from '../StageUtils';
import styles from './styles.module.css';
import { faTriangleExclamation, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MobileStage = ({
  roomState,
  participantRenderer,
  controlRenderer,
  onLeave,
  isReady,
  sortParticipants,
}: StageProps) => {
  const { isConnecting, error, participants, room } = roomState;
  const [showOverlay, setShowOverlay] = useState(false);
  const sortFn = sortParticipants ?? defaultSortParticipants;
  const [sortedParticipants, setSortedParticipants] = useState(sortFn(participants));

  useEffect(() => {
    setSortedParticipants(sortFn(participants));
  }, [participants, sortFn]);

  if (!isReady && !error) {
    return (
      <div className={styles.emptyState}>
        <div className={styles.emptyStateBody}>
          <div className={styles.emptyStateIconHolder}>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <h3 className={styles.emptyStateHeader}>Starting up...</h3>
          <p className={styles.emptyStateDescription}>Retrieving credentials ...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.emptyState}>
        <div className={styles.emptyStateBody}>
          <div className={styles.emptyStateIconHolder}>
            <FontAwesomeIcon className={styles.emptyStateIcon} icon={faTriangleExclamation} />
          </div>
          <h3 className={styles.emptyStateHeader}>Unable to start call</h3>
          <p className={styles.emptyStateDescription}>Could not establish connection</p>
        </div>
      </div>
    );
  }

  if (isConnecting) {
    return (
      <div className={styles.emptyState}>
        <div className={styles.emptyStateBody}>
          <div className={styles.emptyStateIconHolder}>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <h3 className={styles.emptyStateHeader}>Connecting...</h3>
          <p className={styles.emptyStateDescription}>We're getting things ready for you</p>
        </div>
      </div>
    );
  }
  if (!room) {
    return (
      <div className={styles.emptyState}>
        <div className={styles.emptyStateBody}>
          <FontAwesomeIcon className={styles.emptyStateIcon} icon={faCircleExclamation} />
          <h3 className={styles.emptyStateHeader}>Call Ended</h3>
          <p className={styles.emptyStateDescription}>No active call for this session.</p>
        </div>
      </div>
    );
  }

  if (sortedParticipants.length === 0) {
    return (
      <div className={styles.emptyState}>
        <div className={styles.emptyStateBody}>
          <FontAwesomeIcon className={styles.emptyStateIcon} icon={faCircleExclamation} />
          <h3 className={styles.emptyStateHeader}>Room is empty!</h3>
          <p className={styles.emptyStateDescription}>Waiting for participants...</p>
        </div>
      </div>
    );
  }

  const ParticipantRenderer = participantRenderer ?? ParticipantView;
  const ControlRenderer = controlRenderer ?? ControlsView;

  // find first participant with screen shared
  let screenTrack: VideoTrack | undefined;
  sortedParticipants.forEach((p) => {
    if (screenTrack) {
      return;
    }
    const track = p.getTrack(Track.Source.ScreenShare);
    if (track?.isSubscribed && track.videoTrack) {
      screenTrack = track.videoTrack;
    }
  });

  let otherParticipants = sortedParticipants;
  let participantInFocus: Participant;
  let mainView: ReactElement;
  let participantView: ReactElement[] = [];
  if (screenTrack) {
    mainView = <ScreenShareView track={screenTrack} height="100%" width="100%" />;
  } else if (otherParticipants.length === 0) {
    mainView = <div>no one is in the room</div>;
  } else {
    [participantInFocus, ...otherParticipants] = otherParticipants;
    mainView = (
      <ParticipantRenderer
        key={participantInFocus.identity}
        participant={participantInFocus}
        showOverlay={showOverlay}
        width="100%"
        height="100%"
        orientation="portrait"
        showConnectionQuality
        onMouseEnter={() => setShowOverlay(true)}
        onMouseLeave={() => setShowOverlay(false)}
      />
    );
  }

  if (otherParticipants.length > 0) {
    participantView = otherParticipants.map((participant) => {
      return (
        <ParticipantRenderer
          key={participant.identity}
          participant={participant}
          className={styles.participant}
          aspectWidth={4}
          aspectHeight={3}
          showOverlay={showOverlay}
          onMouseEnter={() => setShowOverlay(true)}
          onMouseLeave={() => setShowOverlay(false)}
        />
      );
    });
  }

  return (
    // global container
    <div className={styles.container}>
      <div className={styles.stage}>{mainView}</div>
      <div className={styles.participantsArea}>{participantView}</div>
      <div className={styles.controlsArea}>
        <ControlRenderer room={room} enableScreenShare={false} onLeave={onLeave} />
      </div>
    </div>
  );
};
