import { faBolt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ControlButton } from '@livekit/react-components';
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const defaultOptions = {
  method: "POST",
  headers: { "Content-Type":"application/json", },
}

export const StartPage = () => {
  // state to pass onto room
  const [code, setCode] = useState<string>('');
  const [connectDisabled, setConnectDisabled] = useState(true);
  // navigation
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      setConnectDisabled(false);
    } else {
      setConnectDisabled(true);
    }
  }, [code]);

  const saveSession = ({ token, url, code}: { token: string, url: string, code: string }) => {
    // set locaStorage
    localStorage.setItem('ro-token', token);
    localStorage.setItem('ro-url', url);

    // enter room
    navigate({ pathname: `/${code.toLowerCase()}` });
  }

  const createAnonymousRoom = async () => {
    let resp;
    try {
      resp = await fetch(`${process.env.REACT_APP_BASE_URL}/v1/calls/start`, defaultOptions);
    } catch (error) {
      return;
    }

    const json = await resp.json();
    saveSession(json)
  };

  const joinRoom = async () => {
    let options = {
      ...defaultOptions,
      body: JSON.stringify({ code: code.toUpperCase() })      
    }

    let resp;
    try {
      resp = await fetch(`${process.env.REACT_APP_BASE_URL}/v1/calls/join`, options);
    } catch (error) {
      return;
    }

    const json = await resp.json();
    saveSession({ ...json, code })
  }

  return (
    <div className="prejoin">
      <Header />
      <main>
        <div className="welcomeText">
          <br /><br />
          <h1>Secure datafree calls for everyone</h1>
          <br />
          <p>Connect, collaborate from anywhere with Reachout.</p>
          <br />
          <br />
        </div>
        <hr />
        <div className="entrySection">
          <div className="createRoomForm">
            <ControlButton
              label="Start instant call"
              icon={faPlus}
              onClick={createAnonymousRoom}
            />
          </div>
          <div>
            <div className="joinRoomForm">
              <input  
                type="text"
                name="code"
                value={code}
                placeholder="Enter call code"
                onChange={(e) => setCode(e.target.value)}
              />
              <ControlButton
                label="Join"
                disabled={connectDisabled}
                icon={faBolt}
                onClick={joinRoom}
              />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
