import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { ControlButton } from '@livekit/react-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthStart = () => {
  // state to pass onto room
  const [mobile, setMobile] = useState<string>('');
  // const [verificationId, setVerificationId] = useState<string>('');
  const [connectDisabled, setConnectDisabled] = useState(true);
  // navigation
  const navigate = useNavigate();

  useEffect(() => {
    if (mobile) {
      setConnectDisabled(false);
    } else {
      setConnectDisabled(true);
    }
  }, [mobile]);

  const connectToRoom = async () => {
    let options = {
      method: "POST",
      headers: { "Content-Type":"application/json", },
      body: JSON.stringify({ phone_number: mobile, country_code: '234' })      
    }

    let resp;
    try {
      resp = await fetch('https://api.chatd.reachout.dev/v1/auth/claim/start', options);
    } catch (error) {
      console.log(error)
      return;
    }

    const json = await resp.json();
    
    const params = {
      mobile: mobile,
      verification_id: json['verification_id']
    }
    navigate({
      pathname: '/auth/complete',
      search: '?' + new URLSearchParams(params).toString(),
    });
  };

  return (
    <div className="prejoin">
      <main>
        <h2>LiveKit Video</h2>
        <hr />
        <div className="entrySection">
          <div>
            <div className="label">LiveKit URL</div>
            <div>
              <input type="text" name="mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} />
            </div>
          </div>
          <div className="options">
            <ControlButton
              label="Connect"
              disabled={connectDisabled}
              icon={faBolt}
              onClick={connectToRoom}
            />
          </div>
        </div>
      </main>
      <footer>
        This page is built with <a href="https://github.com/livekit/livekit-react">LiveKit React</a>
        &nbsp; (
        <a href="https://github.com/livekit/livekit-react/blob/master/example/src/PreJoinPage.tsx">
          source
        </a>
        )
      </footer>
    </div>
  );
};
