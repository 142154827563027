import '@livekit/react-components/dist/index.css';
import React from 'react';
import { ChakraProvider, extendTheme, type ThemeConfig } from '@chakra-ui/react'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PreJoinPage } from './PreJoinPage';
import { RoomPage } from './RoomPage';
import { AuthStart } from './auth/AuthStart';
import { AuthComplete } from './auth/AuthComplete';
import { StartPage } from './StartPage';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

// 3. extend the theme
const theme = extendTheme({ config })

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <div className="container">
        <React.StrictMode>
          <Router>
            <Routes>
              <Route path="/auth/login" element={<AuthStart />} />
              <Route path="/auth/complete" element={<AuthComplete />} />
              <Route path="/prejoin" element={<PreJoinPage />} />
              <Route path="/:code" element={<RoomPage />} />
              <Route path="/" element={<StartPage />} />
            </Routes>
          </Router>
        </React.StrictMode>
      </div>
    </ChakraProvider>
  );
};

export default App;
